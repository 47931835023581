<template lang="html">
    <footer class="page-footer">
        <div class="flex flex-justify page-footer-content">
            <div class="logo-container">
                <img src="@/assets/logo.png" alt="friver-logo" width="253" height="83"/>
            </div>
            <div class="flex link-container">
                <div class="link-group">
                    <div class="text-title links-title">Menu</div>
                    <router-link to="/" class="link-item">Home</router-link>
                    <router-link to="/writer-benefits" class="link-item">Writer Benefits</router-link>
                </div>
                <div class="link-group">
                    <div class="text-title links-title">Contact Us</div>
                    <div class="media-link-group">
                        <a href="https://facebook.com/storywriterservice/" class="media-link-item">
                            <img src="@/assets/img/facebook.png" width="28" height="28"/>
                        </a>
                    </div>
                </div>
                <div class="link-group">
                    <div class="text-title links-title">Download BeeNovel App</div>
                    <div class="media-link-group">
                        <a href="https://apps.apple.com/us/app/beenovel/id1520164" class="media-link-item">
                            <img src="@/assets/img/app-store.png" width="96" height="32"/>
                        </a>
                    </div>
                    <div class="media-link-group">
                        <a href="https://play.google.com/store/apps/details?id=com.wishowex.beenovel"
                           class="media-link-item">
                            <img src="@/assets/img/google-play.png" width="96" height="32"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>

export default {
    name: 'DesktopFooter'
}
</script>

<style lang="less" scoped>
@import (reference) "~base.less";

.page-footer {
    border-top: 1px solid @color-bg;
    padding: 48px 0 88px;
    background-color: @color-white;

    &-content {
        margin: 0 auto;
        width: @page-width - 200px;
    }

    .logo-container {
        .img-logo {
        }
    }

    .link-container {
        .link-group {
            margin-left: 124px;
        }

        .link-title {
            font-size: @fontsize-lg;
            margin-bottom: 8px;
        }

        .link-item {
            display: block;
            margin-top: 20px;
        }

        .media-link-group {
            margin-top: 20px;
        }

        .media-link-item {
            margin-left: 20px;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}
</style>
