<template lang="html">
<header class="page-header">
    <div class="flex flex-middle page-header-content">
        <img src="@/assets/logo.png" alt="friver-logo" width="174" height="57" />
        <div class="flex-item-1 flex flex-middle page-header-links">
            <div>
                <router-link v-for="tab in tabs"
                    :key="tab.key"
                    :to="tab.url"
                    class="header-link"
                    :class="{ 'active': tab.key === currentTab }">
                    {{ tab.title }}</router-link>
            </div>
            <a href="https://friverwriting.com/">
                <button class="btn btn-xm btn-primary is-round btn-write">Start Writing</button>
            </a>
        </div>
    </div>
</header>
</template>

<script>
export default {
    name: 'DesktopHeader',
    data() {
        return {
            currentTab: '',
            tabs: [{
                key: 'home',
                title: 'Home',
                url: '/'
            }, {
                key: 'writer-benefits',
                title: 'Writer Benefits',
                url: '/writer-benefits'
            }],
        }
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        '$route': 'initTab'
    },
    created() {
        this.initTab();
    },
    methods: {
        initTab() {
            this.currentTab = this.$route.meta.tab || '';
        }
    }
}
</script>

<style lang="less" scoped>
@import (reference) "~base.less";

.page-header {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    right: 0;
    background-color: fade(@color-white, 70%);

    &-content {
        margin: 0 auto;
        width: @page-width;

        .page-header-links {
            justify-content: flex-end;
        }
        .header-link {
            position: relative;
            margin-left: 52px;
            padding: 15px 10px;
            font-size: @fontsize-lg;
            color: @color-text-title;
            font-weight: bold;
            &:hover {
                color: @color-primary;
            }
            &.active {
                color: @color-primary;
                &::after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    transform: translateX(-50%);
                    width: 38px;
                    height: 3px;
                    border-radius: 2px;
                    background-color: @color-primary;
                }
            }
        }
        .btn-write {
            margin-left: 118px;
            padding-left: 30px;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                top: 6px;
                left: 6px;
                width: 18px;
                height: 18px;
                background: url('~@/assets/img/icon/feather.png') no-repeat center center / 100% 100%;
            }
        }
    }
}
</style>
